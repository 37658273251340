<template>
  <div>

    <Hero />

    <section class="quote">
      <div class="quote__container wrapper">
        <!-- <h2 class="quote__title">{{$t('check_3_quotes')}}</h2> -->
        <router-view/>
      </div>
    </section>

    <Content />

  </div>
</template>

<script>
import Hero from '@/components/Hero.vue';
import Content from '@/components/Content.vue';

export default {
  name: "Quote",
  components: {
    Hero,
    Content
  }
}
</script>