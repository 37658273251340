<template>
    <div class="wrapper">
  
      <p class="mainContainer__mainTitle mainContainer__mainTitle--mod01">{{ $t('mainContainer.getInstantQuote') }}</p>
  
        <div class="navigation navigation--mod1">
          <router-link class="navigation__link navigation__link--active navigation__link--done" to="/" style="pointer-events: none !important;">
            <span><i class="ri-check-fill"></i></span> {{ $t('mainContainer.stepOne') }}
          </router-link>
          <router-link class="navigation__link navigation__link--active navigation__link--done" to="/quote" style="pointer-events: none !important;">
            <span><i class="ri-check-fill"></i></span> {{ $t('mainContainer.stepTwo') }}
          </router-link>
          <router-link class="navigation__link navigation__link--active navigation__link--done" to="/quote/additional" style="pointer-events: none !important;">
            <span><i class="ri-check-fill"></i></span> {{ $t('mainContainer.stepThree') }}
          </router-link>
          <router-link class="navigation__link navigation__link--active navigation__link--done" to="/" style="pointer-events: none !important;">
            <span><i class="ri-check-fill"></i></span> {{ $t('mainContainer.stepFour') }}
          </router-link>
        </div>
  
        <p class="mainContainer__mainTitle mainContainer__mainTitle--mod01 mainContainer__mainTitle--mod02">{{ $t('mainContainer.bestPriceAndService') }}</p>
  
        <div class="mainContainer__box mainContainer__box--mod04 mainContainer__box--mod03" style="justify-content: center">
            <p class="mainContainer__endSubtitle">{{ $t('messages.thankYou') }} {{ $t('messages.submitted') }} {{ $t('messages.agentWillContact') }} {{ $t('messages.appreciation') }}</p>


  
            <p class="mainContainer__alertTextLoading" v-if="emailLoading">{{ $t('mainContainer.sending') }}</p>
            <p class="mainContainer__alertText" v-if="emailSend">{{ $t('mainContainer.emailSentSuccessfully') }}</p>
            <p class="mainContainer__alertTextError" v-if="emailError">{{ $t('mainContainer.emailError') }}</p>
  
            <p class="mainContainer__endSubtitle" style="margin-top:30px;">{{ $t('mainContainer.bookWithAgent') }}</p>
  
            <a href="tel:8706001993" target="_blank" class="mainContainer__endPhone">{{ $t('mainContainer.phone') }}</a>
  
  
        </div>
  

  
    </div>
  </template>
  
  <script>
  import emailjs from '@emailjs/browser';
  import axios from 'axios';
  
  export default {
    name: "Finish",
    data() {
      return {
        emailSend: false,
        emailError: false,
        emailLoading: false
      }
    },
    methods: {
      backAction() {
  
        this.$router.push({ name: "Additional" });
        
      }
    }
  }
  </script>