<template>
  <div class="wrapper">
    <div class="navigation navigation--mod1">
      <router-link class="navigation__link navigation__link--active navigation__link--done" to="/" style="pointer-events: none !important;"><span><strong>1</strong></span> {{ $t('successful.transport') }}</router-link>
      <router-link class="navigation__link navigation__link--active navigation__link--done" to="/" style="pointer-events: none !important;"><span><strong>2</strong></span> {{ $t('successful.delivery') }} </router-link>
      <router-link class="navigation__link navigation__link--active navigation__link--done" to="/" style="pointer-events: none !important;"><span><strong>3</strong></span> {{ $t('successful.pickup') }}</router-link>
      <router-link class="navigation__link navigation__link--active navigation__link--done" to="/" style="pointer-events: none !important;"><span><strong>4</strong></span> {{ $t('successful.finish') }}</router-link>
    </div>

    <p class="mainContainer__mainTitle mainContainer__mainTitle--mod01">{{ $t('successful.paymentMethodSaved') }}</p>
    <p class="mainContainer__mainTitle mainContainer__mainTitle--mod01 mainContainer__mainTitle--mod02" style="margin-bottom:250px">{{ $t('successful.beAware') }}</p>

  </div>
</template>


<script>
export default {
  name: 'Successful',
  mounted() {
    const customerInfo = this.$store.state.customerInfo;

    fetch('https://ratesautotransport.com/api/clear-timer', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(customerInfo)
    })
    .then(response => response.json())
    .then(data => {
      console.log('Timer cleared and deal added:', data);
    })
    .catch(error => {
      console.error('Error clearing timer and adding deal:', error);
    });
  }
}
</script>

<style>

</style>