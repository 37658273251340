<template>
  <Transition name="modal">
    <div v-if="show" class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="modal-header">
            <slot name="header">
              <h3 class="header-text">default header</h3>
            </slot>
          </div>

          <div class="modal-body">
            <slot name="body">
              <p>default body</p>
            </slot>
          </div>

          <!-- Center the button and remove the default footer -->
          <div class="modal-footer">
            <button class="modal-default-button" @click="$emit('close')">Book now!</button>
          </div>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script>
export default {
// Inside PopupCard.vue
props: {
  show: Boolean
},
watch: {
  show(newVal) {
    console.log('Show changed:', newVal); // This should log true after 5 seconds
  }
}

};
</script>

<style scoped>
.modal-mask {
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  max-width: 50%; /* Appropriate width */
  margin: 0 auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 20px; /* Rounded corners for better UI */
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: opacity 0.3s ease, transform 0.3s ease; /* Smooth transition for opacity and transform */
}

.modal-header {
  text-align: center; /* Centered header text */
}

.header-text {
  margin-top: 0;
  color: #434856; /* Stylish font color */
}

.modal-body {
  margin: 20px 0;
  text-align: center; /* Centered text for readability */
}

.modal-default-button {
  display: block;
  margin: 0 auto; /* Center button */
  background-color: #1B3B46; /* Primary button color */
  color: #fff; /* Text color for contrast */
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease; /* Smooth transition for button background color */
}

.modal-default-button:hover {
  background-color: #1B3B46; /* Slightly darker on hover for feedback */
}

/* Transition styles for modal animations */
.modal-enter-active, .modal-leave-active {
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.modal-enter, .modal-leave-to /* Starting and ending state of the modal */ {
  opacity: 0;
  transform: scale(1.1); /* Slightly larger scale for dynamic effect */
}

.modal-enter-to, .modal-leave-from /* Target state for entering and state just before leaving */ {
  opacity: 1;
  transform: scale(1); /* Return to normal size */
}
</style>
