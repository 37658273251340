<template>
  <div class="wrapper">

    <p class="mainContainer__mainTitle mainContainer__mainTitle--mod01">{{ $t('mainContainer.mainTitle') }}</p>

    <div class="mainContainer">

      <div class="mainContainer__box mainContainer__box--mod01">

        <div class="navigation">
          <router-link class="navigation__link navigation__link--active navigation__link--done" to="/"><span><i class="ri-check-fill"></i></span> {{ $t('mainContainer.stepOne') }}</router-link>
          <router-link class="navigation__link navigation__link--active" to="/"><span><i class="ri-map-pin-line"></i></span> {{ $t('mainContainer.stepTwo') }}</router-link>
          <router-link class="navigation__link" to="/"> {{ $t('mainContainer.stepThree') }}</router-link>
          <router-link class="navigation__link" to="/"> {{ $t('mainContainer.stepFour') }}</router-link>
        </div>

        <div class="formContainer">
          <p class="formContainer__infoText" v-if="$store.state.routeData.numberOfVehicles == 1">{{ $t('vehicle.select_type')}}</p>

          <!-- Show this when 'Other' is selected -->
          <div  class="formContainer__carSelection-custom formContainer__inputBox" v-if="isOtherSelected" >
              <!-- Brand selection -->
              <select v-if="!brandNotFound" v-model="selectedBrand" @change="loadYears" style="width: 250px; margin-bottom: 20px">
                <option disabled value="">{{ $t('vehicle.select_brand') }}</option>
                <option v-for="brand in brands" :key="brand">{{ brand }}</option>
              </select>

              <!-- Year selection -->
              <select v-if="!brandNotFound && years.length > 0" v-model="selectedYear" @change="loadModels" style="width: 250px; margin-bottom: 20px">
                <option disabled value="">{{ $t('vehicle.select_year') }}</option>
                <option v-for="year in years" :key="year">{{ year }}</option>
              </select>

              <!-- Model selection -->
              <select v-if="!brandNotFound" v-model="selectedModel" style="width: 250px; margin-bottom: 20px">
                <option disabled value="">{{ $t('vehicle.select_model') }}</option>
                <option v-for="model in models" :key="model">{{ model }}</option>
              </select>

              <!-- Button to show if brand not found -->
              <button  v-if="!brandNotFound" @click="brandNotFound = true">{{ $t('vehicle.my_car_not_here') }}</button>

              <!-- Manual Entry -->
              <input v-if="brandNotFound" v-model="manualEntry" placeholder="Enter your car details manually">
          </div>
          <p class="formContainer__infoText" v-if="$store.state.routeData.numberOfVehicles > 1">{{ $t('vehicle.typeOfVehicle')}}<b>{{ $t('vehicle.firstVehicle') }}</b>{{ $t('vehicle.you_want')}}</p>

          <div class="formContainer__carSelection" v-if="showBasedOnVehicleCount === 'defaultForm' && !isOtherSelected">

            <input type="radio" class="carSelection__radioInput isHidden" name="carSelection" id="atv" value="Atv" v-model="vehicleType">
            <label for="atv" class="carSelection__label">
              <img src="@/assets/img/vehicles/atv.svg" alt="Vehicle">
              <span>Atv</span>
            </label>

            <input type="radio" class="carSelection__radioInput isHidden" name="carSelection" id="motorcycle" value="Motorcycle" v-model="vehicleType">
            <label for="motorcycle" class="carSelection__label">
              <img src="@/assets/img/vehicles/motorcycle.svg" alt="Vehicle">
              <span>Motorcycle</span>
            </label>

            <input type="radio" class="carSelection__radioInput isHidden" name="carSelection" id="jetsky" value="Jetsky" v-model="vehicleType">
            <label for="jetsky" class="carSelection__label">
              <img src="@/assets/img/vehicles/jetsky.svg" alt="Vehicle">
              <span>Jetsky</span>
            </label>

            <input type="radio" class="carSelection__radioInput isHidden" name="carSelection" id="sedan" value="Sedan" v-model="vehicleType">
            <label for="sedan" class="carSelection__label">
              <img src="@/assets/img/vehicles/sedan.svg" alt="Vehicle">
              <span>Sedan</span>
            </label>

            <input type="radio" class="carSelection__radioInput isHidden" name="carSelection" id="smallsuv" value="Small SUV" v-model="vehicleType">
            <label for="smallsuv" class="carSelection__label">
              <img src="@/assets/img/vehicles/smallsuv.svg" alt="Vehicle">
              <span>Small SUV</span>
            </label>

            <input type="radio" class="carSelection__radioInput isHidden" name="carSelection" id="suv" value="SUV" v-model="vehicleType">
            <label for="suv" class="carSelection__label">
              <img src="@/assets/img/vehicles/suv.svg" alt="Vehicle">
              <span>SUV</span>
            </label>

            <input type="radio" class="carSelection__radioInput isHidden" name="carSelection" id="minivan" value="Minivan" v-model="vehicleType">
            <label for="minivan" class="carSelection__label">
              <img src="@/assets/img/vehicles/minivan.svg" alt="Vehicle">
              <span>Minivan</span>
            </label>

            <input type="radio" class="carSelection__radioInput isHidden" name="carSelection" id="pickup1500" value="Pickup 1500" v-model="vehicleType">
            <label for="pickup1500" class="carSelection__label">
              <img src="@/assets/img/vehicles/pickup1500.svg" alt="Vehicle">
              <span>Pickup 1500</span>
            </label>

            <input type="radio" class="carSelection__radioInput isHidden" name="carSelection" id="pickup2500" value="Pickup 2500" v-model="vehicleType">
            <label for="pickup2500" class="carSelection__label">
              <img src="@/assets/img/vehicles/pickup2500.svg" alt="Vehicle">
              <span>Pickup 2500</span>
            </label>

            <input type="radio" class="carSelection__radioInput isHidden" name="carSelection" id="pickup3500" value="Pickup 3500" v-model="vehicleType">
            <label for="pickup3500" class="carSelection__label">
              <img src="@/assets/img/vehicles/pickup3500.svg" alt="Vehicle">
              <span>Pickup 3500</span>
            </label>

            <input type="radio" class="carSelection__radioInput isHidden" name="carSelection" id="pickupfully" value="Pickup Dully" v-model="vehicleType">
            <label for="pickupfully" class="carSelection__label">
              <img src="@/assets/img/vehicles/pickupfully.svg" alt="Vehicle">
              <span>Pickup Dully 3500</span>
            </label>

            <input type="radio" class="carSelection__radioInput isHidden" name="carSelection" id="van1500" value="Van 1500" v-model="vehicleType">
            <label for="van1500" class="carSelection__label">
              <img src="@/assets/img/vehicles/cargovan2500.svg" alt="Vehicle">
              <span>Van 1500</span>
            </label>

            <input type="radio" class="carSelection__radioInput isHidden" name="carSelection" id="van2500" value="Van 2500" v-model="vehicleType">
            <label for="van2500" class="carSelection__label">
              <img src="@/assets/img/vehicles/cargovan1500.svg" alt="Vehicle">
              <span>Van 2500</span>
            </label>

            <input type="radio" class="carSelection__radioInput isHidden" name="carSelection" id="van3500" value="Van 3500" v-model="vehicleType">
            <label for="van3500" class="carSelection__label">
              <img src="@/assets/img/vehicles/cargovan3500.svg" alt="Vehicle">
              <span>Van 3500</span>
            </label>

            <input type="radio" class="carSelection__radioInput isHidden" name="carSelection" id="luxurycar" value="Luxury Car" v-model="vehicleType">
            <label for="luxurycar" class="carSelection__label">
              <img src="@/assets/img/vehicles/luxurycar.svg" alt="Vehicle">
              <span>Luxury Car</span>
            </label>

            <input type="radio" class="carSelection__radioInput isHidden" name="carSelection" id="other" value="Other" v-model="vehicleType">
            <label for="other" class="carSelection__label carSelection__label--mod01" v-on:click="otherForm">
              <img src="@/assets/img/vehicles/other.svg" alt="Vehicle">
              <span>Other</span>
            </label>

          </div>

          <p class="formContainer__infoText formContainer__infoText--2" v-if="$store.state.routeData.numberOfVehicles > 1 && vehicleType != ''">{{ $t('vehicle.typeOfVehicle')}}<strong>{{ $t('vehicle.secondVehicle')}}</strong>{{ $t('vehicle.you_want')}}</p>

          <div class="formContainer__carSelection" v-if="$store.state.routeData.numberOfVehicles > 1 && vehicleType != '' && !isOtherSelected">

            <input type="radio" class="carSelection__radioInput isHidden" name="carSelection2" id="atv2" value="Atv" v-model="vehicleType_2">
            <label for="atv2" class="carSelection__label">
              <img src="@/assets/img/vehicles/atv.svg" alt="Vehicle">
              <span>Atv</span>
            </label>

            <input type="radio" class="carSelection__radioInput isHidden" name="carSelection2" id="motorcycle2" value="Motorcycle" v-model="vehicleType_2">
            <label for="motorcycle2" class="carSelection__label">
              <img src="@/assets/img/vehicles/motorcycle.svg" alt="Vehicle">
              <span>Motorcycle</span>
            </label>

            <input type="radio" class="carSelection__radioInput isHidden" name="carSelection2" id="jetsky2" value="Jetsky" v-model="vehicleType_2">
            <label for="jetsky2" class="carSelection__label">
              <img src="@/assets/img/vehicles/jetsky.svg" alt="Vehicle">
              <span>Jetsky</span>
            </label>

            <input type="radio" class="carSelection__radioInput isHidden" name="carSelection2" id="sedan2" value="Sedan" v-model="vehicleType_2">
            <label for="sedan2" class="carSelection__label">
              <img src="@/assets/img/vehicles/sedan.svg" alt="Vehicle">
              <span>Sedan</span>
            </label>

            <input type="radio" class="carSelection__radioInput isHidden" name="carSelection2" id="smallsuv2" value="Small SUV" v-model="vehicleType_2">
            <label for="smallsuv2" class="carSelection__label">
              <img src="@/assets/img/vehicles/smallsuv.svg" alt="Vehicle">
              <span>Small SUV</span>
            </label>

            <input type="radio" class="carSelection__radioInput isHidden" name="carSelection2" id="suv2" value="SUV" v-model="vehicleType_2">
            <label for="suv2" class="carSelection__label">
              <img src="@/assets/img/vehicles/suv.svg" alt="Vehicle">
              <span>SUV</span>
            </label>

            <input type="radio" class="carSelection__radioInput isHidden" name="carSelection2" id="minivan2" value="Minivan" v-model="vehicleType_2">
            <label for="minivan2" class="carSelection__label">
              <img src="@/assets/img/vehicles/minivan.svg" alt="Vehicle">
              <span>Minivan</span>
            </label>

            <input type="radio" class="carSelection__radioInput isHidden" name="carSelection2" id="pickup15002" value="Pickup 1500" v-model="vehicleType_2">
            <label for="pickup15002" class="carSelection__label">
              <img src="@/assets/img/vehicles/pickup1500.svg" alt="Vehicle">
              <span>Pickup 1500</span>
            </label>

            <input type="radio" class="carSelection__radioInput isHidden" name="carSelection2" id="pickup25002" value="Pickup 2500" v-model="vehicleType_2">
            <label for="pickup25002" class="carSelection__label">
              <img src="@/assets/img/vehicles/pickup2500.svg" alt="Vehicle">
              <span>Pickup 2500</span>
            </label>

            <input type="radio" class="carSelection__radioInput isHidden" name="carSelection2" id="pickup35002" value="Pickup 3500" v-model="vehicleType_2">
            <label for="pickup35002" class="carSelection__label">
              <img src="@/assets/img/vehicles/pickup3500.svg" alt="Vehicle">
              <span>Pickup 3500</span>
            </label>

            <input type="radio" class="carSelection__radioInput isHidden" name="carSelection2" id="pickupfully2" value="Pickup Fully" v-model="vehicleType_2">
            <label for="pickupfully2" class="carSelection__label">
              <img src="@/assets/img/vehicles/pickupfully.svg" alt="Vehicle">
              <span>Pickup Fully</span>
            </label>

            <input type="radio" class="carSelection__radioInput isHidden" name="carSelection2" id="van15002" value="Van 1500" v-model="vehicleType_2">
            <label for="van15002" class="carSelection__label">
              <img src="@/assets/img/vehicles/cargovan2500.svg" alt="Vehicle">
              <span>Van 1500</span>
            </label>

            <input type="radio" class="carSelection__radioInput isHidden" name="carSelection2" id="van25002" value="Van 2500" v-model="vehicleType_2">
            <label for="van25002" class="carSelection__label">
              <img src="@/assets/img/vehicles/cargovan1500.svg" alt="Vehicle">
              <span>Van 2500</span>
            </label>

            <input type="radio" class="carSelection__radioInput isHidden" name="carSelection2" id="van35002" value="Van 3500" v-model="vehicleType_2">
            <label for="van35002" class="carSelection__label">
              <img src="@/assets/img/vehicles/cargovan3500.svg" alt="Vehicle">
              <span>Van 3500</span>
            </label>

            <input type="radio" class="carSelection__radioInput isHidden" name="carSelection2" id="luxurycar2" value="Luxury Car" v-model="vehicleType_2">
            <label for="luxurycar2" class="carSelection__label">
              <img src="@/assets/img/vehicles/luxurycar.svg" alt="Vehicle">
              <span>Luxury Car</span>
            </label>

            <input type="radio" class="carSelection__radioInput isHidden" name="carSelection2" id="other2" value="Other" v-model="vehicleType_2">
            <label for="other2" class="carSelection__label carSelection__label--mod01" v-on:click="otherForm">
              <img src="@/assets/img/vehicles/other.svg" alt="Vehicle">
              <span>Other</span>
            </label>

          </div>

          <p class="formContainer__infoText formContainer__infoText--2" v-if="$store.state.routeData.numberOfVehicles > 2 && vehicleType_2 != ''">{{ $t('vehicle.typeOfVehicle')}}<strong>{{ $t('vehicle.thirdVehicle')}}</strong>{{ $t('vehicle.you_want')}}</p>

          <div class="formContainer__carSelection" v-if="$store.state.routeData.numberOfVehicles > 2 && vehicleType_2 != '' && !isOtherSelected">

            <input type="radio" class="carSelection__radioInput isHidden" name="carSelection3" id="atv3" value="Atv" v-model="vehicleType_3">
            <label for="atv3" class="carSelection__label">
              <img src="@/assets/img/vehicles/atv.svg" alt="Vehicle">
              <span>Atv</span>
            </label>

            <input type="radio" class="carSelection__radioInput isHidden" name="carSelection3" id="motorcycle3" value="Motorcycle" v-model="vehicleType_3">
            <label for="motorcycle3" class="carSelection__label">
              <img src="@/assets/img/vehicles/motorcycle.svg" alt="Vehicle">
              <span>Motorcycle</span>
            </label>

            <input type="radio" class="carSelection__radioInput isHidden" name="carSelection3" id="jetsky3" value="Jetsky" v-model="vehicleType_3">
            <label for="jetsky3" class="carSelection__label">
              <img src="@/assets/img/vehicles/jetsky.svg" alt="Vehicle">
              <span>Jetsky</span>
            </label>

            <input type="radio" class="carSelection__radioInput isHidden" name="carSelection3" id="sedan3" value="Sedan" v-model="vehicleType_3">
            <label for="sedan3" class="carSelection__label">
              <img src="@/assets/img/vehicles/sedan.svg" alt="Vehicle">
              <span>Sedan</span>
            </label>

            <input type="radio" class="carSelection__radioInput isHidden" name="carSelection3" id="smallsuv3" value="Small SUV" v-model="vehicleType_3">
            <label for="smallsuv3" class="carSelection__label">
              <img src="@/assets/img/vehicles/smallsuv.svg" alt="Vehicle">
              <span>Small SUV</span>
            </label>

            <input type="radio" class="carSelection__radioInput isHidden" name="carSelection3" id="suv3" value="SUV" v-model="vehicleType_3">
            <label for="suv3" class="carSelection__label">
              <img src="@/assets/img/vehicles/suv.svg" alt="Vehicle">
              <span>SUV</span>
            </label>

            <input type="radio" class="carSelection__radioInput isHidden" name="carSelection3" id="minivan3" value="Minivan" v-model="vehicleType_3">
            <label for="minivan3" class="carSelection__label">
              <img src="@/assets/img/vehicles/minivan.svg" alt="Vehicle">
              <span>Minivan</span>
            </label>

            <input type="radio" class="carSelection__radioInput isHidden" name="carSelection3" id="pickup15003" value="Pickup 1500" v-model="vehicleType_3">
            <label for="pickup15003" class="carSelection__label">
              <img src="@/assets/img/vehicles/pickup1500.svg" alt="Vehicle">
              <span>Pickup 1500</span>
            </label>

            <input type="radio" class="carSelection__radioInput isHidden" name="carSelection3" id="pickup25003" value="Pickup 2500" v-model="vehicleType_3">
            <label for="pickup25003" class="carSelection__label">
              <img src="@/assets/img/vehicles/pickup2500.svg" alt="Vehicle">
              <span>Pickup 2500</span>
            </label>

            <input type="radio" class="carSelection__radioInput isHidden" name="carSelection3" id="pickup35003" value="Pickup 3500" v-model="vehicleType_3">
            <label for="pickup35003" class="carSelection__label">
              <img src="@/assets/img/vehicles/pickup3500.svg" alt="Vehicle">
              <span>Pickup 3500</span>
            </label>

            <input type="radio" class="carSelection__radioInput isHidden" name="carSelection3" id="pickupfully3" value="Pickup Fully" v-model="vehicleType_3">
            <label for="pickupfully3" class="carSelection__label">
              <img src="@/assets/img/vehicles/pickupfully.svg" alt="Vehicle">
              <span>Pickup Fully</span>
            </label>

            <input type="radio" class="carSelection__radioInput isHidden" name="carSelection3" id="van15003" value="Van 1500" v-model="vehicleType_3">
            <label for="van15003" class="carSelection__label">
              <img src="@/assets/img/vehicles/cargovan2500.svg" alt="Vehicle">
              <span>Van 1500</span>
            </label>

            <input type="radio" class="carSelection__radioInput isHidden" name="carSelection3" id="van25003" value="Van 2500" v-model="vehicleType_3">
            <label for="van25003" class="carSelection__label">
              <img src="@/assets/img/vehicles/cargovan1500.svg" alt="Vehicle">
              <span>Van 2500</span>
            </label>

            <input type="radio" class="carSelection__radioInput isHidden" name="carSelection3" id="van35003" value="Van 3500" v-model="vehicleType_3">
            <label for="van35003" class="carSelection__label">
              <img src="@/assets/img/vehicles/cargovan3500.svg" alt="Vehicle">
              <span>Van 3500</span>
            </label>

            <input type="radio" class="carSelection__radioInput isHidden" name="carSelection3" id="luxurycar3" value="Luxury Car" v-model="vehicleType_3">
            <label for="luxurycar3" class="carSelection__label">
              <img src="@/assets/img/vehicles/luxurycar.svg" alt="Vehicle">
              <span>Luxury Car</span>
            </label>

            <input type="radio" class="carSelection__radioInput isHidden" name="carSelection3" id="other3" value="Other" v-model="vehicleType_3">
            <label for="other3" class="carSelection__label carSelection__label--mod01" v-on:click="otherForm">
              <img src="@/assets/img/vehicles/other.svg" alt="Vehicle">
              <span>Other</span>
            </label>

          </div>

          <p class="formContainer__infoText formContainer__infoText--2" v-if="$store.state.routeData.numberOfVehicles > 3 && vehicleType_3 != ''">{{ $t('vehicle.typeOfVehicle')}}<strong>{{ $t('vehicle.fourthVehicle')}}</strong>{{ $t('vehicle.you_want')}}</p>

          <div class="formContainer__carSelection" v-if="$store.state.routeData.numberOfVehicles > 3 && vehicleType_3 && !isOtherSelected">

            <input type="radio" class="carSelection__radioInput isHidden" name="carSelection4" id="atv4" value="Atv" v-model="vehicleType_4">
            <label for="atv4" class="carSelection__label">
              <img src="@/assets/img/vehicles/atv.svg" alt="Vehicle">
              <span>Atv</span>
            </label>

            <input type="radio" class="carSelection__radioInput isHidden" name="carSelection4" id="motorcycle4" value="Motorcycle" v-model="vehicleType_4">
            <label for="motorcycle4" class="carSelection__label">
              <img src="@/assets/img/vehicles/motorcycle.svg" alt="Vehicle">
              <span>Motorcycle</span>
            </label>

            <input type="radio" class="carSelection__radioInput isHidden" name="carSelection4" id="jetsky4" value="Jetsky" v-model="vehicleType_4">
            <label for="jetsky4" class="carSelection__label">
              <img src="@/assets/img/vehicles/jetsky.svg" alt="Vehicle">
              <span>Jetsky</span>
            </label>

            <input type="radio" class="carSelection__radioInput isHidden" name="carSelection4" id="sedan4" value="Sedan" v-model="vehicleType_4">
            <label for="sedan4" class="carSelection__label">
              <img src="@/assets/img/vehicles/sedan.svg" alt="Vehicle">
              <span>Sedan</span>
            </label>

            <input type="radio" class="carSelection__radioInput isHidden" name="carSelection4" id="smallsuv4" value="Small SUV" v-model="vehicleType_4">
            <label for="smallsuv4" class="carSelection__label">
              <img src="@/assets/img/vehicles/smallsuv.svg" alt="Vehicle">
              <span>Small SUV</span>
            </label>

            <input type="radio" class="carSelection__radioInput isHidden" name="carSelection4" id="suv4" value="SUV" v-model="vehicleType_4">
            <label for="suv4" class="carSelection__label">
              <img src="@/assets/img/vehicles/suv.svg" alt="Vehicle">
              <span>SUV</span>
            </label>

            <input type="radio" class="carSelection__radioInput isHidden" name="carSelection4" id="minivan4" value="Minivan" v-model="vehicleType_4">
            <label for="minivan4" class="carSelection__label">
              <img src="@/assets/img/vehicles/minivan.svg" alt="Vehicle">
              <span>Minivan</span>
            </label>

            <input type="radio" class="carSelection__radioInput isHidden" name="carSelection4" id="pickup15004" value="Pickup 1500" v-model="vehicleType_4">
            <label for="pickup15004" class="carSelection__label">
              <img src="@/assets/img/vehicles/pickup1500.svg" alt="Vehicle">
              <span>Pickup 1500</span>
            </label>

            <input type="radio" class="carSelection__radioInput isHidden" name="carSelection4" id="pickup25004" value="Pickup 2500" v-model="vehicleType_4">
            <label for="pickup25004" class="carSelection__label">
              <img src="@/assets/img/vehicles/pickup2500.svg" alt="Vehicle">
              <span>Pickup 2500</span>
            </label>

            <input type="radio" class="carSelection__radioInput isHidden" name="carSelection4" id="pickup35004" value="Pickup 3500" v-model="vehicleType_4">
            <label for="pickup35004" class="carSelection__label">
              <img src="@/assets/img/vehicles/pickup3500.svg" alt="Vehicle">
              <span>Pickup 3500</span>
            </label>

            <input type="radio" class="carSelection__radioInput isHidden" name="carSelection4" id="pickupfully4" value="Pickup Fully" v-model="vehicleType_4">
            <label for="pickupfully4" class="carSelection__label">
              <img src="@/assets/img/vehicles/pickupfully.svg" alt="Vehicle">
              <span>Pickup Fully</span>
            </label>

            <input type="radio" class="carSelection__radioInput isHidden" name="carSelection4" id="van15004" value="Van 1500" v-model="vehicleType_4">
            <label for="van15004" class="carSelection__label">
              <img src="@/assets/img/vehicles/cargovan2500.svg" alt="Vehicle">
              <span>Van 1500</span>
            </label>

            <input type="radio" class="carSelection__radioInput isHidden" name="carSelection4" id="van25004" value="Van 2500" v-model="vehicleType_4">
            <label for="van25004" class="carSelection__label">
              <img src="@/assets/img/vehicles/cargovan1500.svg" alt="Vehicle">
              <span>Van 2500</span>
            </label>

            <input type="radio" class="carSelection__radioInput isHidden" name="carSelection4" id="van35004" value="Van 3500" v-model="vehicleType_4">
            <label for="van35004" class="carSelection__label">
              <img src="@/assets/img/vehicles/cargovan3500.svg" alt="Vehicle">
              <span>Van 3500</span>
            </label>

            <input type="radio" class="carSelection__radioInput isHidden" name="carSelection4" id="luxurycar4" value="Luxury Car" v-model="vehicleType_4">
            <label for="luxurycar4" class="carSelection__label">
              <img src="@/assets/img/vehicles/luxurycar.svg" alt="Vehicle">
              <span>Luxury Car</span>
            </label>

            <input type="radio" class="carSelection__radioInput isHidden" name="carSelection4" id="other4" value="Other" v-model="vehicleType_4">
            <label for="other4" class="carSelection__label carSelection__label--mod01" v-on:click="otherForm">
              <img src="@/assets/img/vehicles/other.svg" alt="Vehicle">
              <span>Other</span>
            </label>

          </div>


          <div class="formContainer__submitBox">
            <button class="formContainer__submit" v-on:click="backAction">{{ $t('back') }}</button>
            <button disabled class="formContainer__submit" id="continueStep" v-on:click="nextStep">{{ $t('next') }}</button>
          </div>

        </div>

      </div>

      <div class="mainContainer__box mainContainer__box--mod01">

        <div class="mainContainer__informationalBox">

          <p class="infoSection__title infoSection__title--mod01">{{ $t('route_information') }}</p>

          <div class="informationalBox__infoSection informationalBox__infoSection--mod01">
            <div class="infoSection__container infoSection__container--33">
              <p class="infoSection__title">{{ $t('from') }}</p>
              <p class="infoSection__select" id="selectFrom"> {{ this.$store.state.routeData.fromAddress || '-' }} </p>
            </div>
            <div class="infoSection__container infoSection__container--33 infoSection__container--flex">
              <div class="infoSection__line"></div>
              <div class="infoSection__miles" id="distanceMiles"> {{ this.$store.state.routeData.distanceFromTo + ' MI' }} </div>
              <div class="infoSection__line"></div>
            </div>
            <div class="infoSection__container infoSection__container--33">
              <p class="infoSection__title infoSection__textAlignRight">{{ $t('to') }}</p>
              <p class="infoSection__select infoSection__textAlignRight" id="selectTo"> {{ this.$store.state.routeData.toAddress || '-' }} </p>
            </div>
          </div>

          <div class="informationalBox__infoSection">
            <div class="infoSection__container">
              <p class="infoSection__title">{{ $t('pickupdate')}}</p>
              <p class="infoSection__select"> {{ this.$store.state.routeData.pickupDate || '-' }} </p>
            </div>
            <div class="infoSection__container">
              <p class="infoSection__title">{{ $t('how_many_veh')}}</p>
              <p class="infoSection__select"> {{ this.$store.state.routeData.numberOfVehicles || '-' }} </p>
            </div>
          </div>

          <div class="informationalBox__infoSection">
            <div class="infoSection__container">
            <p class="infoSection__title" v-if="$store.state.routeData.numberOfVehicles == 1">{{ $t('transported_vehicule') }}</p>
            <p class="infoSection__title" v-if="$store.state.routeData.numberOfVehicles > 1">{{ $t('transported_vehicules') }}</p>
            <p class="infoSection__select" v-if="$store.state.routeData.numberOfVehicles == 1"> {{ vehicleType || $t('selectVehicle.single') }} </p>
            <p class="infoSection__select" v-if="$store.state.routeData.numberOfVehicles > 1">1. {{ vehicleType || $t('selectVehicle.first') }} </p>
            <p class="infoSection__select" v-if="$store.state.routeData.numberOfVehicles > 1">2. {{ vehicleType_2 || $t('selectVehicle.second') }} </p>
            <p class="infoSection__select" v-if="$store.state.routeData.numberOfVehicles > 2">3. {{ vehicleType_3 || $t('selectVehicle.third') }} </p>
            <p class="infoSection__select" v-if="$store.state.routeData.numberOfVehicles > 3">4. {{ vehicleType_4 || $t('selectVehicle.fourth') }} </p>

            </div>
          </div>

        </div>

      </div>

    </div>

  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: "Vehicle",
  data() {
    return {
      vehicleType: '',
      vehicleType_2: '',
      vehicleType_3: '',
      vehicleType_4: '',
      continueButtonCheck: false,
      selectedBrand: '',
      selectedModel: '',
      selectedYear: '',
      manualEntry: '',
      brands: ["Acura", "Audi", "BMW", "Buick", "Cadillac", "Chevrolet", "Genesis", "GMC", "Honda", "Hyundai", "INFINITI", "Jaguar", "Kia", "Land Rover", "Lincoln", "Mazda", "MINI", "Mitsubishi", "Nissan", "Polestar", "Subaru", "Toyota", "Volvo", "Alfa Romeo", "Bentley", "Chrysler", "Dodge", "FIAT", "Ford", "Jeep", "Karma", "Lamborghini", "Lexus", "Lucid", "Maserati", "McLaren", "Mercedes-Benz", "Porsche", "Ram", "Rivian", "Rolls-Royce", "Tesla", "Volkswagen", "Aston Martin", "Lotus", "Ferrari", "smart", "Scion", "Suzuki", "Fisker", "Maybach", "Saab", "Mercury", "HUMMER", "Pontiac", "Bugatti", "Saturn", "Spyker", "Isuzu", "Panoz", "Oldsmobile", "Daewoo", "Plymouth", "AM General", "Eagle", "Geo", "VinFast"],
      models: [],
      years: Array.from({ length: 74 }, (_, i) => 2023 - i),
      brandNotFound: false,
      isOtherSelected: false,
    }
  },
  computed: {
  showBasedOnVehicleCount() {
      let numberOfVehicles = this.$store.state.routeData.numberOfVehicles;
      let vehicleNotEmpty = this.vehicleType !== '';
      if (numberOfVehicles > 3 && vehicleNotEmpty) {
        return 'formForGreaterThan3';
      }
      if (numberOfVehicles > 2 && vehicleNotEmpty) {
        return 'formForGreaterThan2';
      }
      if (numberOfVehicles > 1 && vehicleNotEmpty) {
        return 'formForGreaterThan1';
      }
      return 'defaultForm';
    }
  },
  methods: {
    async loadModels() {
      // Reset models array
      this.models = [];

      try {
        const response = await axios.get(`https://vpic.nhtsa.dot.gov/api/vehicles/getmodelsformakeyear/make/${this.selectedBrand}/modelyear/${this.selectedYear}?format=json`);
        this.models = response.data.Results.map(item => item.Model_Name);
      } catch (error) {
        console.error("An error occurred while fetching data: ", error);
      }
    },
    loadYears() {
      // Reset selected year and models
      this.selectedYear = '';
      this.models = [];
    },
    nextStep() {

      const vehicleData = {
        vehicleType: this.vehicleType,
        vehicleType_2: this.vehicleType_2,
        vehicleType_3: this.vehicleType_3,
        vehicleType_4: this.vehicleType_4
      }

      if (this.isOtherSelected) {
        if (this.manualEntry) {
          vehicleData.manualEntry = this.manualEntry;
        } else {
          vehicleData.selectedBrand = this.selectedBrand;
          vehicleData.selectedModel = this.selectedModel;
          vehicleData.selectedYear = this.selectedYear;
        }
      }
      this.$store.dispatch('SAVE_VEHICLE_STEP_ACTION', vehicleData);
        // Determine next route based on whether a custom vehicle was selected
      const nextRoute = this.isOtherSelected ? 'CustomAdditional' : 'Additional';
      this.$router.replace({ name: nextRoute });


    },
    otherForm() {
      this.isOtherSelected = true;
    },
    backAction() {
      this.$router.push({ name: "Home" });
    },
    checkIfCanContinue() {

      if (this.$store.state.routeData.numberOfVehicles == 1 && this.vehicleType != '') {

        this.continueButtonCheck = true;

      } else if (this.$store.state.routeData.numberOfVehicles == 2 && this.vehicleType != '' && this.vehicleType_2 != '') {

        this.continueButtonCheck = true;

      } else if (this.$store.state.routeData.numberOfVehicles == 3 && this.vehicleType != '' && this.vehicleType_2 != '' && this.vehicleType_3 != '') {

        this.continueButtonCheck = true;

      } else if (this.$store.state.routeData.numberOfVehicles == 4 && this.vehicleType != '' && this.vehicleType_2 != '' && this.vehicleType_3 != '' && this.vehicleType_4 != '') {

        this.continueButtonCheck = true;

      }
      if (
          (this.selectedModel !== '' && this.selectedModel !== null) ||
          (this.manualEntry !== '' && this.manualEntry !== null)
        ) {
          this.continueButtonCheck = true;
        }

    }
  },
  watch: {
    vehicleType(newValue, oldValue) {
      if (newValue.length > 1) {
        this.checkIfCanContinue();
      }
    },
    vehicleType_2(newValue, oldValue) {
      if (newValue.length > 1) {
        this.checkIfCanContinue();
      }
    },
    vehicleType_3(newValue, oldValue) {
      if (newValue.length > 1) {
        this.checkIfCanContinue();
      }
    },
    vehicleType_4(newValue, oldValue) {
      if (newValue.length > 1) {
        this.checkIfCanContinue();
      }
    },
    selectedModel(newValue, oldValue) {
    if (newValue) { // Check for a valid selected model
      this.checkIfCanContinue()
      }
    },
    manualEntry(newValue, oldValue) {
      if (newValue) { // Check for a valid manual entry
        this.checkIfCanContinue()
      }
    },
    continueButtonCheck(newValue, oldValue) {
      if (newValue === true) {
        document.querySelector('#continueStep').removeAttribute("disabled");
      }
    }
  }
}
</script>