<template>
	<div class="wrapper pageNotFound">
		<p class="pageNotFound__title">404</p>
		<p class="pageNotFound__subTitle">Page Not Found</p>
	</div>
</template>

<script>
export default {
	name: 'PageNotFound'
}
</script>

<style scoped>

	.pageNotFound {
		margin: 200px auto;
		color: var(--SecondaryColor);
	}

	.pageNotFound__title {
		font-size: 20rem;
		line-height: 20rem;
		font-weight: 800;
		text-align: center;
	}

	.pageNotFound__subTitle {
		font-size: 3rem;
		font-weight: 800;
		text-align: center;
		margin-top: -15px;
	}

	@media only screen and (max-width: 768px) {

		.pageNotFound {
			margin: 100px auto;
		}

		.pageNotFound__title {
			font-size: 10rem;
			line-height: 10rem;
		}

		.pageNotFound__subTitle {
			font-size: 2rem;
			margin-top: -15px;
		}

	}

</style>