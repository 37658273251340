<template>
    <div>
      <h1 style="text-align: center; color: black; margin-bottom: 3%; margin-top: 1%;">Admin Dashboard</h1>
      <div class="dashboard" >
        
        <!-- Left Column: Table -->
        <div class="left-col">
          <table>
            <thead>
              <tr>
                <th>Min</th>
                <th>Max</th>
                <th>Price</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(band, index) in pricingBands" :key="index">
                <td>{{ band.min }}</td>
                <td>{{ band.max }}</td>
                <td>{{ band.price }}</td>
                <td id="tdButton"><button id="xButton" @click="removeBandAndSave(index)">X</button></td>
              </tr>
            </tbody>
          </table>
        </div>
        
        <!-- Right Column: Inputs -->
        <div class="right-col">
          <!-- Staging Area for new inputs -->
          <div class="input-row">
            <div class="input-group">
                <label for="min">Min</label>
                <input class="customInputBox" id="min" v-model="newBand.min" type="number" placeholder="Min" />
            </div>
            <div class="input-group">
                <label for="max">Max</label>
                <input class="customInputBox" id="max" v-model="newBand.max" type="number" placeholder="Max" />
            </div>
            <div class="input-group">
                <label for="price">Price</label>
                <input class="customInputBox" id="price" v-model="newBand.price" type="number" placeholder="Price" />
            </div>
        </div>

          <div class="add-update-buttons">
              <button @click="addNewBand">Add Distance Price</button>
          </div>
        </div>
        
      </div>
    </div>
  </template>
  
  <!-- Add your script and style tags here -->
  
  <script>
  export default {
    data() {
      return {
        pricingBands: [],
        newBand: { min: 0, max: 0, price: 0 }
      };
    },
    methods: {
      async addNewBand() {
      this.pricingBands.push({ ...this.newBand }); // Push a copy of newBand to pricingBands
      this.newBand = { min: 0, max: 0, price: 0 }; // Reset newBand for the next input
      const response = await fetch('https://ratesautotransport.com/api/pricing', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(this.pricingBands),
        });
        if (response.status === 200) {
          alert('Pricing updated successfully');
        } else {
          alert('Failed to update pricing');
        }
    },
      removeBand(index) {
        this.pricingBands.splice(index, 1);
      },      
        async removeBandAndSave(index) {
        const response = await fetch(`https://ratesautotransport.com/api/pricing/${index}`, {
            method: 'DELETE',
        });

        if (response.status === 200) {
            this.removeBand(index);
            alert('Successfully deleted');
        } else {
            alert('Failed to delete');
        }
       },
    },
      async created() {
      const response = await fetch('https://ratesautotransport.com/api/pricing');
        if (response.status === 200) {
            this.pricingBands = await response.json();
        } else {
            alert('Failed to load pricing data');
        }
    },
  };
  </script>
  
  
<style>
.newBackground {
    background: linear-gradient(140deg, rgba(255,243,0,1) 5%, rgba(255,255,255,1) 70%);
}
.preFooter, footer{
    display: none;
}
.dashboard {
  display: flex;
  justify-content: space-between;
}
.right-col {
  flex: 1;
  padding: 2px;
  box-sizing: border-box;  
  margin-right: 15rem;
}
.left-col {
  margin-left: 10rem;
  flex: 1;
  padding: 2px;
  box-sizing: border-box;
}

/* Table styles */
table {
  width: 100%;
  border-collapse: collapse;
}

th, td {
  border: 1px solid #ccc;
  text-align: left;
  padding: 8px;
}

#tdButton {
    border: 0px;
}
/* Make the "X" button smaller */
#xButton {
  font-size: 12px !important;
  padding: 5px 10px !important;
}

/* If you have other buttons that shouldn't have this style, you can be more specific. For example: */
.add-update-buttons button {
  margin-right: 10px !important;
  padding: 10px 20px !important;
}
button {
  margin-right: 10px;  /* Add right margin to space the buttons apart */
  padding: 10px 20px;  /* Add padding for a better look */
}

.input-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

h2 {
    margin-bottom: 5%;
}
.input-group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-right: 20px;
}

.input-group label {
  margin-bottom: 5px;
  font-weight: bold;
  text-align: center;
  margin-left: 25%;
}

.customInputBox {
  padding: 10px;
  width: 100px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 10px;
}

</style>