<template>
  <div>
    <h1>Admin Login</h1>
    <input type="password" v-model="password" placeholder="Enter Password" />
    <button @click="login">Login</button>
  </div>
</template>

<script>
export default {
  data() {
    return {
      password: '',
    };
  },
  methods: {
    login() {
      if (this.password === 'deasa2312sad5?SDaE') {
        this.$router.push({ name: '74sde4tbvb', query: { qasd41dscdsse: this.password } });
      } else {
        alert('Incorrect password');
      }
    },
  },
};
</script>

  <style scoped>
  div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;  /* This makes the div take up the full viewport height */
    background: linear-gradient(140deg, rgba(255,243,0,1) 0%, rgba(255,255,255,1) 39%);
  }

  h1 {
    margin-bottom: 20px;
    color: black
  }

  input {
    margin-bottom: 20px;
    padding: 10px;
    font-size: 16px;
  }

  button {
    padding: 10px 20px;
    font-size: 16px;
  }
</style>
