<template>
	<footer class="footer">
		<div class="footer__container wrapper">

			<div class="footer__box footer__box--mod1">

				<img class="footer__image" src="@/assets/img/white-logo.png" alt="Logo">

			</div>
			<div class="footer__box footer__box--mod2">

				<p class="footer__copyright">©2023 - Rates Auto Transport. All rights reserved.</p>

			</div>
			<div class="footer__box footer__box--mod3">

				<a class="footer__button" href="tel:8706001993">{{ $t('nav.contact') }}</a>

			</div>

		</div>
	</footer>
</template>

<script>
export default {
	name: 'Footer'
}
</script>

<style scoped>

.footer__container {
	display: flex;
	background-color: var(--SecondaryColor);
	margin-bottom: 40px;
	border-radius: 20px;
	padding: 10px 40px 10px 30px;
}

.footer__box {
	display: flex;
	align-items: center;
}

.footer__copyright {
	color: var(--ComplementaryColor03);
	text-align: center;
	width: 100%;
}

.footer__box--mod1 {
	width: 25%;
}

.footer__box--mod2 {
	width: 50%;
}

.footer__box--mod3 {
	width: 25%;
	justify-content: end;
}

.footer__button {
  background-color: var(--PrimaryColor);
  color: var(--SecondaryColor);
  font-weight: 600;
  padding: 10px 25px;
  border-radius: 5px;
}

</style>