<template>
  <div class="preFooter">
    <div class="preFooter__container wrapper">

      <div class="preFooter__box">

        <div class="preFooter__subBox preFooter__subBox--mod1">
          <img class="preFooter__image" src="@/assets/img/prefooter.png" alt="Icon">
        </div>

        <div class="preFooter__subBox preFooter__subBox--mod2">
          <h2 class="preFooter__title" v-html="$t('preFoot_title')"></h2>
          <p class="preFooter__description" v-html="$t('preFooter')"></p>
        </div>

      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: 'PreFooter'
}
</script>

<style scoped>

.preFooter__box {
  display: flex;
  margin: 20px auto 40px auto;
  width: 100%;
  max-width: 1100px;
  background-color: var(--PrimaryColor);
  padding: 40px;
  border-radius: 30px;
}

.preFooter__subBox {
  width: 50%;
  padding: 10px;
}

.preFooter__subBox--mod1 {
  display: flex;
  justify-content: center;
  align-items: center;
}

.preFooter__image {
  width: 100%;
  margin-bottom: -50px;
}

.preFooter__title {
  font-size: 4rem;
  text-align: right;
  margin-bottom: 30px;
}

.preFooter__description {
  text-align: right;
}

@media only screen and (max-width: 768px) {
  .preFooter__image {
    width: 70%;
    margin-bottom: -15px !important
  }
}

</style>