import { createStore } from 'vuex'
export default createStore({
  state: {
    routeData: {},
    vehicleData: {},
    additionalData: {},
    finalData: {},
    quoteUniqueId: '',
    reservationType: '',
    documentId: '',
    checkout: {
      transport: {},
      pickup: {},
      delivery: {}
    },
    timerId: null,
    customerInfo: {}
  },
  mutations: {
    SET_CUSTOMER_INFO(state, payload) {
      state.customerInfo = payload;
    },
    SET_TIMER_ID(state, timerId) {
      state.timerId = timerId;
    },
    CLEAR_TIMER_ID(state) {
      state.timerId = null;
    },
    SAVE_ROUTE_STEP_MUTATION(state, data) {
      state.routeData = data;
    },
    SAVE_VEHICLE_STEP_MUTATION(state, data) {
      state.vehicleData = data;
    },
    SAVE_ADDITIONAL_STEP_MUTATION(state, data) {
      state.additionalData = data;
    },
    async PROCESS_DATA_MUTATION(state) {

      if (state.routeData.numberOfVehicles === 1) {

        // +270 = Ganancia Tuyoyo
        const totalFinal = await totalPrice(state) + 270;
        
        state.finalData = {
          totalPrice: new Intl.NumberFormat('en-US').format((totalFinal).toFixed(2)),
          totalPriceWithoutDiscount: new Intl.NumberFormat('en-US').format((totalFinal + 50).toFixed(2)),
          oneToFiveDaysPrice: new Intl.NumberFormat('en-US').format((totalFinal).toFixed(2)),
          oneToThreeDaysPrice: new Intl.NumberFormat('en-US').format((totalFinal + 100).toFixed(2)),
          oneToTenDaysPrice: new Intl.NumberFormat('en-US').format((totalFinal - 100).toFixed(2))
        }

      } else if (state.routeData.numberOfVehicles === 2) {

        // -100 = Descuento por 2 carros | +350 = Ganancia Tuyoyo
        const totalFinal =  ((await totalPrice(state) + await totalPrice2(state)) - 100) + 350;

        state.finalData = {
          totalPrice: new Intl.NumberFormat('en-US').format((totalFinal).toFixed(2)),
          totalPriceWithoutDiscount: new Intl.NumberFormat('en-US').format((totalFinal + 50).toFixed(2)),
          oneToFiveDaysPrice: new Intl.NumberFormat('en-US').format((totalFinal).toFixed(2)),
          oneToThreeDaysPrice: new Intl.NumberFormat('en-US').format((totalFinal + 100).toFixed(2)),
          oneToTenDaysPrice: new Intl.NumberFormat('en-US').format((totalFinal - 100).toFixed(2))
        }

      } else if (state.routeData.numberOfVehicles === 3) {

        // -150 = Descuento por 3 carros | +450 = Ganancia Tuyoyo
        const totalFinal = ((await totalPrice(state) + await totalPrice2(state) + await totalPrice3(state)) - 150) + 450;

        state.finalData = {
          totalPrice: new Intl.NumberFormat('en-US').format((totalFinal).toFixed(2)),
          totalPriceWithoutDiscount: new Intl.NumberFormat('en-US').format((totalFinal + 50).toFixed(2)),
          oneToFiveDaysPrice: new Intl.NumberFormat('en-US').format((totalFinal).toFixed(2)),
          oneToThreeDaysPrice: new Intl.NumberFormat('en-US').format((totalFinal + 100).toFixed(2)),
          oneToTenDaysPrice: new Intl.NumberFormat('en-US').format((totalFinal - 100).toFixed(2))
        }

      } else if (state.routeData.numberOfVehicles === 4) {

        // -200 = Descuento por 4 carros | +500 = Ganancia Tuyoyo
        const totalFinal = ((await totalPrice(state) + await totalPrice2(state) + await totalPrice3(state) + await totalPrice4(state)) - 200) + 500;

        state.finalData = {
          totalPrice: new Intl.NumberFormat('en-US').format((totalFinal).toFixed(2)),
          totalPriceWithoutDiscount: new Intl.NumberFormat('en-US').format((totalFinal + 50).toFixed(2)),
          oneToFiveDaysPrice: new Intl.NumberFormat('en-US').format((totalFinal).toFixed(2)),
          oneToThreeDaysPrice: new Intl.NumberFormat('en-US').format((totalFinal + 100).toFixed(2)),
          oneToTenDaysPrice: new Intl.NumberFormat('en-US').format((totalFinal - 100).toFixed(2))
        }

      }

    },
    SAVE_QUOTE_ID_MUTATION(state, data) {
      state.quoteUniqueId = data;
    },
    CLEAR_STORE_MUTATION(state) {
      state.routeData = {};
      state.vehicleData = {};
      state.additionalData = {};
      state.finalData = {};
      state.quoteUniqueId = '';
      state.documentId = '';
      state.reservationType = '';
      state.checkout = {
        transport: {},
        pickup: {},
        delivery: {}
      };
    },
    SAVE_RESERVATION_TYPE_MUTATION(state, data) {
      state.reservationType = data;
    },
    SAVE_CHECKOUT_TRANSPORT_MUTATION(state, data) {
      state.checkout.transport = data;
    },
    SAVE_CHECKOUT_PICKUP_MUTATION(state, data) {
      state.checkout.pickup = data;
    },
    SAVE_CHECKOUT_DELIVERY_MUTATION(state, data) {
      state.checkout.delivery = data;
    },
    CHECKOUT_SAVE_DATA_TO_STORE_MUTATION(state, data) {
      const { routeData, vehicleData, additionalData, finalData, quoteUniqueId, reservationType, checkout } = data;
      state.routeData = routeData;
      state.vehicleData = vehicleData;
      state.additionalData = additionalData;
      state.finalData = finalData;
      state.quoteUniqueId = quoteUniqueId;
      state.reservationType = reservationType;
      state.checkout = checkout;
    },
    SAVE_DOCUMENT_ID_MUTATION(state, data) {
      state.documentId = data;
    }
  },
  actions: {
    SAVE_CUSTOMER_INFO_ACTION({ commit }, payload) {
      commit('SET_CUSTOMER_INFO', payload);
    },
    setTimerId({ commit }, timerId) {
      commit('SET_TIMER_ID', timerId);
    },
    clearTimerId({ commit }) {
      commit('CLEAR_TIMER_ID');
    },
    SAVE_ROUTE_STEP_ACTION({commit}, data) {
      commit('SAVE_ROUTE_STEP_MUTATION', data);
    },
    SAVE_VEHICLE_STEP_ACTION({commit}, data) {
      commit('SAVE_VEHICLE_STEP_MUTATION', data);
    },
    SAVE_ADDITIONAL_STEP_ACTION({commit}, data) {
      commit('SAVE_ADDITIONAL_STEP_MUTATION', data);
      commit('PROCESS_DATA_MUTATION');
    },
    SAVE_QUOTE_ID_ACTION({commit}, data) {
      commit('SAVE_QUOTE_ID_MUTATION', data);
    },
    CLEAR_STORE_ACTION({commit}) {
      commit('CLEAR_STORE_MUTATION');
    },
    SAVE_RESERVATION_TYPE_ACTION({commit}, data) {
      commit('SAVE_RESERVATION_TYPE_MUTATION', data);
    },
    SAVE_CHECKOUT_TRANSPORT_ACTION({commit}, data) {
      commit('SAVE_CHECKOUT_TRANSPORT_MUTATION', data);
    },
    SAVE_CHECKOUT_PICKUP_ACTION({commit}, data) {
      commit('SAVE_CHECKOUT_PICKUP_MUTATION', data);
    },
    SAVE_CHECKOUT_DELIVERY_ACTION({commit}, data) {
      commit('SAVE_CHECKOUT_DELIVERY_MUTATION', data);
    },
    CHECKOUT_SAVE_DATA_TO_STORE_ACTION({commit}, data) {
      commit('CHECKOUT_SAVE_DATA_TO_STORE_MUTATION', data);
    },
    SAVE_DOCUMENT_ID_ACTION({commit}, data) {
      commit('SAVE_DOCUMENT_ID_MUTATION', data);
    }
  }
});

/* FUNCTIONS */

async function fetchPricing() {
  const res = await fetch("https://ratesautotransport.com/api/getPricing");
  const pricingBands = await res.json();
  return pricingBands;
}

async function distancePrice(distance) {
  const pricingBands = await fetchPricing();
  for (let band of pricingBands) {
    if (distance >= band.min && distance <= band.max) {
      return distance * band.price;
    }
  }
  return null; // or some default value
}
// function distancePrice(distance) {

//   if (distance <= 124) {
//     return distance * 2.5
//   } else if (distance >= 125 && distance <= 499) {
//     return distance * 1.2
//   } else if (distance >= 500 && distance <= 749) {
//     return distance * 0.8
//   } else if (distance >= 750 && distance <= 999) {
//     return distance * 0.7
//   } else if (distance >= 1000 && distance <= 1249) {
//     return distance * 0.59
//   } else if (distance >= 1250 && distance <= 1499) {
//     return distance * 0.6 
//   } else if (distance >= 1500 && distance <= 1749) {
//     return distance * 0.63
//   } else if (distance >= 1750 && distance <= 1999) {
//     return distance * 0.6
//   } else if (distance >= 2000 && distance <= 2249) {
//     return distance * 0.49
//   } else if (distance >= 2250 && distance <= 2499) {
//     return distance * 0.47
//   } else if (distance >= 2500 && distance <= 2749) {
//     return distance * 0.44
//   } else if (distance >= 2750) {
//     return distance * 0.42
//   }

// }

function checkVehicleCondition(condition) {
  if (condition === 'Inoperable') {
    return 250
  } else {
    return 0
  }
}

function checkTransportType(condition) {
  if (condition === 'Enclosed') {
    return 550
  } else {
    return 0
  }
}

function checkVehicleSize(type) {

  if (type === 'Motorcycle' || type === 'Atv' || type === 'Jetsky') {
    return -100
  } else if (type === 'Sedan' || type === 'Small SUV') {
    return 0
  } else if (type === 'SUV' || type === 'Minivan' || type === 'Pickup 1500' || type === 'Van 1500') {
    return 100
  } else if (type === 'Pickup 2500' || type === 'Van 2500') {
    return 200
  } else if (type === 'Pickup 3500' || type === 'Van 3500') {
    return 250
  } else if (type === 'Pickup Dully' || type === 'Luxury Car') {
    return 300
  } else if (type === 'Other') {
    return 0 /* PENDIENTE */
  } else {
    return 0
  }

}

function cityValue(state, city) {

  const cityType = {
    FL: {
      Pensacola: 3,
      KeyWest: 4,
      WaltonBeach: 4,
      Sarasota: 4,
      PanamaCity: 3,
      Talahasse: 3,
      Blountstown: 3,
      Jasper: 3,
      Destin: 3,
      PortStJoe: 3,
      StGeorgeIsland: 4,
      Apalachicola: 4
    },
    TX: {
      ElPaso: 3,
      Laredo: 3,
      Burkburnett: 4,
      Marathon: 4,
      Raymondville: 4,
      ChulaVista: 4,
      Nacogdoches: 4,
      Amarillo: 3,
      Lufkin: 4,
      FortWorth: 3,
      CorpusChristi: 4,
      Amarillo: 3,
      Brownsville: 4,
      McAllen: 4,
      Killeen: 3,
      MtPleasant: 3,
      SanMarcos: 3,
      EdgecliffVillage: 3,
      Galveston: 3,
      Beamont: 4,
      SanIsidro: 4
    },
    MN: {
      Avon: 2,
      Plymouth: 2,
      SaintPaul: 2,
      Minneapolis: 2,
      AppleValley: 2,
      Rochester: 2
    },
    CA: {
      LosAngeles: 1,
      ElCentro: 4,
      Chico: 4,
      Napa: 4,
      Visalia: 3,
      SanDiego: 2,
      Tulare: 3,
      Livingston: 3,
      Bakersfield: 4,
      TahoeLake: 4,
      Bloomington: 3,
      LaHabra: 3,
      SantaRosa: 2,
      SanLuisObispo: 4,
      PasoRobles: 4,
      Oxnard: 4,
      ThousandOaks: 4,
      ChulaVista: 3
    },
    LA: {
      Longville: 3
    },
    VA: {
      Chatham: 4
    },
    NY: {
      Buffalo: 4,
      NiagaraFalls: 4,
      LeRoy: 4,
      PearlRiver: 4,
      YorktownHeights: 3,
      Rome: 3
    },
    CO: {
      Pueblo: 3,
      Denver: 1,
      Greeley: 2,
      Hudson: 3
    },
    NV: {
      Reno: 3,
      LasVegas: 1,
      NorthLasVegas: 1,
      Henderson: 2
    },
    AZ: {
      Yuma: 4,
      Nogales: 4,
      Flagstaff: 4,
      Phoenix: 1
    },
    WA: {
      EastWenatchee: 3,
      Seattle: 2,
      Bellevue: 2,
      Duvall: 2,
      Pasco: 2,
      Bernalillo: 2,
      Graham: 2,
      Puyallup: 2,
      Tacoma: 2,
      Yakima: 2
    },
    GA: {
      Rome: 3,
      Louisville: 4
    },
    NC: {
      RockyMount: 4,
      Kannapolis: 4,
      Dobson: 4,
      Knightdale: 3
    },
    DE: {
      NewCastle: 4
    },
    NJ: {
      Vineland: 3,
      Windsor: 3
    },
    OH: {
      Cleveland: 2,
      Minerva: 4,
      Ashtabula: 4,
      Cincinnati: 2,
      Euclid: 3,
      NorthCanton: 3
    },
    IL: {
      Beardstown: 4
    },
    TN: {
      Sevierville: 4
    },
    CT: {
      Hamden: 3
    },
    MT: {
      Missoula: 3,
      Bozeman: 3
    },
    OR: {
      Portland: 2,
      Salem: 4,
      Eugene: 4
    },
    AL: {
      Mobile: 4
    },
    UT: {
      SaltLakeCity: 2,
      WestJordan: 2,
      Sandy: 2,
      SouthJordan: 2
    },
    NE: {
      Omaha: 2,
      Lincoln: 1,
      GrandIsland: 2,
      Dorchester: 2,
      Rochester: 2
    },
    NM: {
      Albuquerque: 1,
      LosLunas: 2,
      SantaFe: 2,
      Bernalillo: 2,
      Albuquerque: 2
    },
    IA: {
      DesMoines: 1,
      SiouxCity: 2,
      Marshalltown: 2
    },
    ME: {
      Bath: 2
    },
    WI: {
      Milwaukee: 1
    },
    IN: {
      Indianapolis: 2
    },
    SC: {
      Graniteville: 2
    },
    SD: {
      SiouxFalls: 2
    },
    WV: {
      ShadySpring: 2,
      HarpersFerry: 2
    },
    ID: {
      Boise: 2
    },
    ND: {
      Glenburn: 2
    },
    RI: {
      Providence: 2
    },
    KY: {
      Louisville: 2
    }

  }

  const stateType = {
    AZ: 2,
    AL: 3,
    AR: 4,
    CA: 1,
    CO: 2,
    CT: 2,
    DE: 3,
    FL: 1,
    GA: 1,
    ID: 4,
    IL: 2,
    IN: 3,
    IA: 3,
    KS: 3,
    KY: 4,
    LA: 3,
    ME: 4,
    MD: 3,
    MA: 3,
    MI: 3,
    MN: 3,
    MS: 3,
    MO: 2,
    MT: 4,
    NE: 4,
    NV: 3,
    NH: 3,
    NJ: 1,
    NM: 3,
    NY: 2,
    NC: 2,
    ND: 4,
    OH: 3,
    OK: 3,
    OR: 3,
    PA: 3,
    RI: 4,
    SC: 2,
    SD: 4,
    TN: 2,
    TX: 1,
    UT: 3,
    VT: 4,
    VA: 3,
    WA: 2,
    WV: 4,
    WI: 3,
    WY: 4
  }

  const processValue = (value) => {

    if (value === 1) {
      return -50
    } else if (value === 2) {
      return 0
    } else if (value === 3) {
      return 50
    } else if (value === 4) {
      return 150
    } else {
      return 0
    }

  }

  try {

    const processedCityValue = processValue(cityType[state][city]);
    const processedStateValue = processValue(stateType[state]);

    if (processedCityValue === 0) {

      return processedStateValue

    } else {

      return processedCityValue

    }

    
  } catch (err) {

    const processedStateValue = processValue(stateType[state]);

    return processedStateValue
    
  }
  
}

function poundsPrice(pounds) {

  if (pounds > 100) {
    const result = (pounds - 100)
    console.log(result)
    return result
  } else {
    console.log(pounds)
    return 0
  }

}

async function totalPrice(state) {

  const distanceTotalPrice = await distancePrice(Number(state.routeData.distanceFromTo));
  const vehicleConditionPrice = checkVehicleCondition(state.additionalData.vehicleCondition);
  const transportTypePrice = checkTransportType(state.additionalData.vehicleType);
  const vehicleSizePrice = checkVehicleSize(state.vehicleData.vehicleType);
  const fromAddressPrice = cityValue(state.routeData.fromAddressState.toUpperCase(), state.routeData.fromAddressCity.replace(/\s/g, ''));
  const toAddressPrice = cityValue(state.routeData.toAddressState.toUpperCase(), state.routeData.toAddressCity.replace(/\s/g, ''));
  const lbPrice = poundsPrice(Number(state.additionalData.vehiclePounds));

  const total = distanceTotalPrice + vehicleConditionPrice + transportTypePrice + vehicleSizePrice + fromAddressPrice + toAddressPrice + lbPrice;

  return total

}

async function totalPrice2(state) {

  const distanceTotalPrice = await distancePrice(Number(state.routeData.distanceFromTo));
  const vehicleConditionPrice = checkVehicleCondition(state.additionalData.vehicleCondition_2);
  const transportTypePrice = checkTransportType(state.additionalData.vehicleType_2);
  const vehicleSizePrice = checkVehicleSize(state.vehicleData.vehicleType_2);
  const fromAddressPrice = cityValue(state.routeData.fromAddressState.toUpperCase(), state.routeData.fromAddressCity.replace(/\s/g, ''));
  const toAddressPrice = cityValue(state.routeData.toAddressState.toUpperCase(), state.routeData.toAddressCity.replace(/\s/g, ''));
  const lbPrice = poundsPrice(Number(state.additionalData.vehiclePounds_2));

  const total = distanceTotalPrice + vehicleConditionPrice + transportTypePrice + vehicleSizePrice + fromAddressPrice + toAddressPrice + lbPrice;

  return total

}

async function totalPrice3(state) {

  const distanceTotalPrice = await distancePrice(Number(state.routeData.distanceFromTo));
  const vehicleConditionPrice = checkVehicleCondition(state.additionalData.vehicleCondition_3);
  const transportTypePrice = checkTransportType(state.additionalData.vehicleType_3);
  const vehicleSizePrice = checkVehicleSize(state.vehicleData.vehicleType_3);
  const fromAddressPrice = cityValue(state.routeData.fromAddressState.toUpperCase(), state.routeData.fromAddressCity.replace(/\s/g, ''));
  const toAddressPrice = cityValue(state.routeData.toAddressState.toUpperCase(), state.routeData.toAddressCity.replace(/\s/g, ''));
  const lbPrice = poundsPrice(Number(state.additionalData.vehiclePounds_3));

  const total = distanceTotalPrice + vehicleConditionPrice + transportTypePrice + vehicleSizePrice + fromAddressPrice + toAddressPrice + lbPrice;

  return total

}

async function totalPrice4(state) {

  const distanceTotalPrice = await distancePrice(Number(state.routeData.distanceFromTo));
  const vehicleConditionPrice = checkVehicleCondition(state.additionalData.vehicleCondition_4);
  const transportTypePrice = checkTransportType(state.additionalData.vehicleType_4);
  const vehicleSizePrice = checkVehicleSize(state.vehicleData.vehicleType_4);
  const fromAddressPrice = cityValue(state.routeData.fromAddressState.toUpperCase(), state.routeData.fromAddressCity.replace(/\s/g, ''));
  const toAddressPrice = cityValue(state.routeData.toAddressState.toUpperCase(), state.routeData.toAddressCity.replace(/\s/g, ''));
  const lbPrice = poundsPrice(Number(state.additionalData.vehiclePounds_4));

  const total = distanceTotalPrice + vehicleConditionPrice + transportTypePrice + vehicleSizePrice + fromAddressPrice + toAddressPrice + lbPrice;

  return total

}